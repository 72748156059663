import AboutSection from '../components/sections/about-section';

const About = () => {
    return (
        <>
            <AboutSection />
        </>
    );
};

export default About;
